<template>
  <div>
    <lkps-table
      :api-endpoint="apiEndpoint"
      :headers="headers"
      :form="form"
      single-export="Dapat Ditinjau"
    />
  </div>
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_dtps_bkps',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Nama Lengkap Dosen Tetap*',
          dataKey: 'data_master_dosen.full_name',
        }, {
          label: 'NIDN/NIDK',
          dataKey: 'data_master_dosen.nidn_or_nidk',
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Tanggal Lahir',
          dataKey: 'data_master_dosen.tanggal_lahir',
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return moment(data).format('DD-MM-YYYY')
          }
        }, {
          label: 'Sertifikat Pendidik',
          dataKey: 'data_master_dosen.sertifikat_pendidik',
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Jabatan Fungsional',
          dataKey: 'jabatan_fungsional',
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Gelar Akademik',
          dataKey: 'gelar_akademik',
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Pendidikan S1, S2, S3  dan Asal PT',
          dataKey: 'data_master_dosen.combine_jenjang',
        }, {
          label: 'Bidang Keahlian Setiap Jenjang Pendidikan',
          dataKey: 'data_master_dosen.bidang_keahlian',
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen Lengkap Tetap*',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Jabatan Fungsional',
          name: 'jabatan_fungsional',
          dataKey: 'jabatan_fungsional',
          type: 'select',
          options: ['Guru Besar', 'Lektor Kepala', 'Lektor', 'Asisten Ahli', 'Tenaga Pengajar'],
          
        }, {
          label: 'Gelar Akademik',
          name: 'gelar_akademik',
          dataKey: 'gelar_akademik',
          type: 'text',
          
        }],
      }
    }
  },
}
</script>
